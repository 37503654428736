import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import "../utils/normalize.css"
import "../utils/css/screen.css"

const RecentPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Recent Work"
        keywords={[`about`, `Hollye`, `Schultz`, `Tucson`]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2 className="m-t-0">Recent Work</h2>
          <br />
          <Img
            fluid={data.amillaImage.childImageSharp.fluid}
            fadeIn
            alt="Amilla Screenshot"
          />
          <div className="card-description-bg p-b-0">
            <h4 className="case-study-title recent-title">Amilla.com</h4>{" "}
            &nbsp;&nbsp;
            <a href="https://amilla.com" target="_blank">
              ⧉ VISIT SITE
            </a>
            <p className="m-b-0">
              Led the design and re-branding of the luxurious Amilla Maldives
              Resort & Residences. Designed the brand style guide, mockups,
              wireframes, and all website pages. Collaborated with developers on
              the front-end implementation and contributed SASS code. This
              project won two of the highest awards from{" "}
              <a
                target="_blank"
                href="https://enter.amcpros.com/dotcomm/entry/amilla-maldives/"
              >
                DotComm Awards
              </a>{" "}
              (Platinum Awards) for Website Creativity and Redesign.
            </p>
          </div>
          <div class="kg-width-full">
            <hr />
          </div>
          <Img
            fluid={data.caprichoImage.childImageSharp.fluid}
            fadeIn
            alt="Capricho Screenshot"
          />
          <div className="card-description-bg p-b-0">
            <h4 className="case-study-title recent-title">CaprichoAspen.com</h4>{" "}
            &nbsp;&nbsp;
            <a href="https://caprichoaspen.com/" target="_blank">
              ⧉ VISIT SITE
            </a>
            <p>
              Led the branding and design of a new Shopify store for
              hand-crafted leather travel bag company, CaprichoAspen. Modernized
              the logo and color palette, created conceptual mockups and a brand
              style guide, and designed all site pages and components in Figma.
              Collaborated with Shopify developers on the front-end CSS code.
            </p>
          </div>
          <div class="kg-width-full">
            <hr />
          </div>
          <Img
            fluid={data.dodieeImage.childImageSharp.fluid}
            fadeIn
            alt="Dodiee Screenshot"
          />
          <div className="card-description-bg p-b-0">
            <h4 className="case-study-title recent-title">Dodiee.com</h4>{" "}
            &nbsp;&nbsp;
            <a href="https://dodiee.com" target="_blank">
              ⧉ VISIT SITE
            </a>
            <p>
              Collaborated with fellow designers on a new Shopify site for
              luxury knitwear company, Dodiee. Designed several responsive web
              pages in Figma, including a custom blog template. Coded several
              site elements using the Liquid templating language and CSS.
            </p>
          </div>
          <div class="kg-width-full">
            <hr />
          </div>
          <Img
            fluid={data.businessImage.childImageSharp.fluid}
            fadeIn
            alt="Business Simultor Screenshot"
          />
          <div className="card-description-bg p-b-0">
            <h4 className="case-study-title recent-title">
              Business Simulator
            </h4>{" "}
            &nbsp;&nbsp;
            <span>(No public site)</span>
            <p>
              Designed the user interface for a University's interactive
              business simulation course. This web app included user onboarding
              flows, user dashboards, a leaderboard screen, and complex forms.
              Illustrated a dynamic map within the game and advised developers
              on data states and interactivity.
            </p>
          </div>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    amillaImage: file(relativePath: { eq: "amilla-screenshot.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    caprichoImage: file(relativePath: { eq: "capricho-screenshot.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dodieeImage: file(relativePath: { eq: "dodiee-screenshot.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    businessImage: file(relativePath: { eq: "business-screenshot.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <RecentPage location={props.location} data={data} {...props} />
    )}
  />
)
